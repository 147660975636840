<template lang="pug">
</template>
<script setup lang="ts">
import { useAuth } from '~/store/auth'

const auth = useAuth()
const router = useRouter()

auth.signOut()
router.replace({ name: 'home' })
</script>